<template>
	<div class="InvLogin">
		<Header></Header>
		<div class="InvLogin-box md:px-12">
			<Breadcrumb :routes="routes"></Breadcrumb>
			<div class="supply-title">
				<div class="title">
					<h1>BP-FLAC Bridge Campaign</h1>
					<h3>
						<span>Bridge Your Assets, Earn the Medals,</span>
						<span>Secure Future Airdrops!</span>
					</h3>
				</div>
			</div>
			<div class="InvLoginCard-box">
				<InvLogin></InvLogin>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import Breadcrumb from '@/components/common/Breadcrumb.vue'
	import InvLogin from '@/components/invLogin/invLogin.vue'
	import { mapState } from 'vuex'
	export default {
		name: 'Faucet',
		components: {
			Header,
			Footer,
			Breadcrumb,
			InvLogin,
		},
		data() {
			return {
				isShow: false,
				routes: [{
						path: '/',
						breadcrumbName: 'Home',
					},

					{
						path: '/InvLogin',
						breadcrumbName: 'InvLogin',
					},
				],
			}
		},
		computed: {
			...mapState(['isConnect', 'address']),
		},
		mounted() {
			// 0x09128f1081771c9ad474363ff9cf3845977e39a2&code=S0tCRkxkX1I3OGZWZ1VhZVJmZS1zMldXUWh1aUJ3Mk1JUU9hREhQVWFMQndoOjE3MDIwMzE3MjYxNDA6MTowOmFjOjE
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.InvLogin {
		position: relative;
		background-color: #000000;
		color: #ffffff;

		.InvLogin-box {
			padding: 14px 80px;

			@screen md {}
		}


		.supply-title {
			.title {
				text-align: center;
				margin-top: 42px;
				margin-bottom: 26px;

				h1 {
					margin: 0;
					font-size: 32px;
					color: #fff;
					font-weight: 700;

					@screen md {
						font-size: 20px;
						text-align: center;
					}
				}

				h3 {
					margin: 0;
					padding-top: 16px;
					display: flex;
					justify-content: center;

					span {
						font-size: 24px;
						color: rgba(255, 255, 255, .3);
						font-weight: 700;
					}

					@screen md {
						flex-direction: column;

						span {
							font-size: 16px;
							text-align: center;
							font-weight: 500;
						}
					}
				}



				@screen md {
					display: block;
					margin-top: 32px;
					margin-bottom: 16px;


				}

			}

			p {
				text-align: center;
				color: rgba(255, 255, 255, 0.60);
				font-size: 20px;
				font-weight: 700;
				font-style: normal;
				line-height: 32.5px;
				/* 162.5% */
				letter-spacing: 2px;
				margin: 0;

				@screen md {
					font-size: 10px;
					line-height: 18px;
					letter-spacing: 0.1px;
				}
			}
		}

		.InvLoginCard-box {
			display: flex;
			justify-content: center;
		}
	}
</style>