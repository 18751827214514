<template>
	<div class="invLogin">
		<h1>Enter your invite code to join the team </h1>
		<div class="input-box flex-between">
			<input v-for="(code, index) in verificationCodes " :key="index" v-model="verificationCodes[index]"
				@input="handleInput(index, $event)" @keydown="handleKeyDown(index, $event)" maxlength="1"
				class="verification-input" />
		</div>
		<div class="btn-box">
			<template v-if="isEnter">
				<a-button class="error" type="primary" @click="jointeam">
					Enter invite code
				</a-button>
			</template>
			<template v-else>
				<a-button type="primary" @click="jointeam">
					Redeem invite code
				</a-button>
			</template>
			
		</div>
	</div>
</template>

<script>
	
	import { jointeam } from '@/utils/active.api'
	
	import { getSign } from '@/web3utils/base/index.js'
	import { mapState } from 'vuex'
	export default {
		name: 'invLogin',
		data() {
			return {
				inviteCode:'',
				verificationCodes: ['', '', '', '', '', '', '', ''],
			}
		},
		computed: {
			...mapState(['addressinfo', 'address']),
			isEnter(){
				const a = this.verificationCodes.join('');
				return a.length<8
			},
		},
		mounted() {
			this.init()
		},
		methods: {
			
			async jointeam(){
				const code = this.verificationCodes.join('')
				if(code.length!=8){
					return this.$message.error('Please enter the invitation code')
				}
				const signmsg = await getSign(code, this.address)
				if (!signmsg) return this.$message.error('Failed to request signature. Please try again')
				const { success,msg } = await jointeam({
					selfaddress:this.address,
					invcode:code,
					signmsg: signmsg
				})
				if(success){
					this.$message.success(msg)
					this.$router.push('Dashboard?isTab=2')
				}else{
					this.verificationCodes = ['', '', '', '', '', '', '', ''];
					this.$message.error(msg)
				}
			},
			
			init(){
				this.inviteCode = this.$route.query.inviteCode;
				if(this.inviteCode){
					this.verificationCodes =  this.inviteCode.split('')
				}
			},
			handleInput(index, event) {
				const value = event.target.value;
				this.verificationCodes[index] = value;
				// 自动跳到下一个输入框
				if (value && index < this.verificationCodes.length - 1) {
					const nextInput = event.target.nextElementSibling;
					if (nextInput) {
						this.$nextTick(() => {
							nextInput.focus();
						});
					}
				}
			},
			handleKeyDown(index, event) {
				// 处理删除操作
				if (event.key === 'Backspace' && !event.target.value && index > 0) {
					const prevInput = event.target.previousElementSibling;
					if (prevInput) {
						this.$nextTick(() => {
							prevInput.focus();
						});
					}
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.invLogin {
		margin: 60px 0;
		width: 764px;
		padding: 56px 80px 56px 80px;
		border-radius: 20px;
		border: 1px solid rgba(255, 255, 255, 0.2);
		text-align: center;

		h1 {
			
			font-size: 14px;
			font-weight: 500;
			line-height: 14px;
			letter-spacing: 0em;
			color: rgba(255, 255, 255, 1);

		}


		.input-box {
			margin: 60px 0;
			display: flex;

			input {
				width: 64px;
				height: 64px;
				background: rgba(36, 36, 36, 1);

				
				font-size: 24px;
				font-weight: 700;
				line-height: 24px;
				text-align: center;
				color: rgba(255, 255, 255, 1);
				background-size: 100% 100%;

				&:focus {
					outline: none;
					border-color: #75b1ff;
					box-shadow: 0 0 5px #0099ff;
				}
			}
		}

		.btn {
			width: 320px;
			height: 52px;

			padding: 16px 0px 16px 0px;
			border-radius: 100px;



			font-size: 16px;
			color: #fff;
			font-weight: 400;
			padding: 7px 0;
			background: linear-gradient(to right, #203dfc, #33c4f2);
			border: none;

		}

		.btn-box {
			.ant-btn-primary {
				width: 320px;
				height: unset;
				line-height: unset;
				
				padding: 10px 0px;
				border-radius: 100px;

				font-size: 20px;
				color: #fff;
				font-weight: 400;
				background: linear-gradient(to right, #203dfc, #33c4f2);
				border: none;
			}
			.error{
				color: #5A5A5A;
				background: #242424;
			}
		}

		@screen md {
			width: 354px;
			padding: 30px 10px;

			.input-box { 
				input {
					width: 34px;
					height: 34px;
				}
			}
 
			.btn-box {
				.ant-btn-primary {
					height: unset;
					line-height: unset;
					width: 200px;
					padding: 8px 40px;
					font-size:  14px;
				}
			} 


		}
	}
</style>